exports.components = {
  "component---src-pages-akce-js": () => import("./../../../src/pages/akce.js" /* webpackChunkName: "component---src-pages-akce-js" */),
  "component---src-pages-detskedomovynase-js": () => import("./../../../src/pages/detskedomovynase.js" /* webpackChunkName: "component---src-pages-detskedomovynase-js" */),
  "component---src-pages-hleda-se-talent-2022-js": () => import("./../../../src/pages/hleda-se-talent-2022.js" /* webpackChunkName: "component---src-pages-hleda-se-talent-2022-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-nasteam-js": () => import("./../../../src/pages/nasteam.js" /* webpackChunkName: "component---src-pages-nasteam-js" */),
  "component---src-pages-podpora-js": () => import("./../../../src/pages/podpora.js" /* webpackChunkName: "component---src-pages-podpora-js" */),
  "component---src-pages-podporujinas-js": () => import("./../../../src/pages/podporujinas.js" /* webpackChunkName: "component---src-pages-podporujinas-js" */),
  "component---src-pages-pomahej-s-nami-js": () => import("./../../../src/pages/pomahej-s-nami.js" /* webpackChunkName: "component---src-pages-pomahej-s-nami-js" */),
  "component---src-pages-spolupracujeme-s-js": () => import("./../../../src/pages/spolupracujeme-s.js" /* webpackChunkName: "component---src-pages-spolupracujeme-s-js" */),
  "component---src-pages-vanoce-js": () => import("./../../../src/pages/vanoce.js" /* webpackChunkName: "component---src-pages-vanoce-js" */)
}

